// import React from "react";
import { baseUrl } from "../../services/Environment";
import React, { useRef, useState, useEffect } from "react";
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const HeaderOverlay = ({ bannerURL, bannerALT, img }) => {
  const mainVideo = baseUrl + bannerURL;
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);

  console.log("mainVideo", mainVideo);
  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return shouldUseImage ? (
    <img
      src={mainVideo}
      alt="nclthailand"
      style={{ width: "100vw", height: "auto" }}
    />
  ) : (
    <div className="overlay">
      {/* <video className="w-100" autoPlay muted loop playsinline >
          <source src={baseUrl + bannerURL} type="video/mp4" />
        </video> */}
      {/* <video
        style={{ width: "100vw" }}
        // className="w-100 h-auto"
        autoPlay
        muted
        loop
        playsInline
        preload="metadata"
      >
        <source src={mainVideo} type="video/mp4" />
      </video> */}

      <video
        style={{ width: "100vw" }}
        autoPlay
        muted
        loop
        playsInline
        preload="metadata"
        onError={(e) => {
          // กรณีที่วิดีโอโหลดไม่ได้ สามารถตั้งค่าให้แสดงภาพนิ่งแทน
          e.target.outerHTML = `<img src="${img}" alt="nclthailand" style="width: 100vw; height: auto;" />`;
        }}
      >
        <source src={mainVideo} type="video/mp4" />
        {/* กรณีเบราว์เซอร์ไม่รองรับ <video> */}
        <img
          src={mainVideo}
          alt="nclthailand"
          style={{ width: "100vw", height: "auto" }}
        />
      </video>

      {/* <div
        ref={videoParentRef}
        dangerouslySetInnerHTML={{
          __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              preload="metadata"
              class="w-100 h-auto"

            >
            <source src="${mainVideo}" type="video/mp4" />
            </video>`,
        }}
      /> */}
    </div>
  );
};

export default HeaderOverlay;
