import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStrAPI from "../../hooks/useStrAPI";
import { toast } from "react-toastify";
// import nodemailer from "nodemailer";
// import SweetAlert2 from "react-sweetalert2";

function Footer() {
  const lang = localStorage.getItem("ncl_lang")

  // const [lang, setLang] = useState();
  const { t, i18n } = useTranslation()
  const { getContact, postForm } = useStrAPI()
  const [swalProps, setSwalProps] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false) // เพิ่ม state ใหม่
  const [formData, setFormData] = useState({
    Name: "",
    lastName: "",
    Phone: "",
    Email: "",
    Description: "",
  })

  const [errors, setErrors] = useState({
    Name: "",
    lastName: "",
    Phone: "",
    Email: "",
    Description: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
    setErrors({
      ...errors,
      [name]: "",
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Form validation
    let formIsValid = true
    const newErrors = {}

    // Check if first name is empty
    if (!formData.Name.trim()) {
      formIsValid = false
      newErrors.Name = "First name is required"
    }

    // Check if last phoneNumber is empty
    if (!formData.Phone.trim()) {
      formIsValid = false
      newErrors.Phone = "Last Phone is required"
    }

    // Check if email is valid
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailPattern.test(formData.Email)) {
      formIsValid = false
      newErrors.Email = "Invalid email address"
    }
    // Check if last message is empty
    if (!formData.Description.trim()) {
      formIsValid = false
      newErrors.Description = "Last description is required"
    }

    // Update errors state
    setErrors(newErrors)

    // If form is valid, proceed with form submission
    if (formIsValid) {
      let jsonData = {
        data: {
          Name: `${formData.Name} ${formData.lastName}`,
          Phone: formData.Phone,
          Email: formData.Email,
          Description: formData.Description,
        },
      }
      setIsSubmitting(true) // เปลี่ยนสถานะปุ่มเป็นล็อก
      toast.warning(
        `${
          lang == "th"
            ? "กำลังส่งข้อมูล โปรดรอ"
            : "Sending contact, please wait"
        }`
      )
      try {
        // Wait for postForm to finish
        await postForm("contact-forms", jsonData)

        setSwalProps({
          show: true,
          text: "Welcome to NCL Thailand",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })

        // Reset form data
        setFormData({
          Name: "",
          lastName: "",
          Phone: "",
          Email: "",
          Description: "",
        })

        toast.success(
          `${
            lang == "th"
              ? "ส่งข้อมูลติดต่อสำเร็จ"
              : "Sent contact successful"
          }`
        )
      } catch (error) {
        toast.error(
          `${
            lang == "th"
              ? "ส่งข้อมูลติดต่อไม่สำเร็จ"
              : "Sent contact failure"
          }`
        )
        console.error(error) // log the error if something goes wrong
      } finally {
        // Unlock the submit button after the postForm completes
        setIsSubmitting(false) // ปลดล็อกปุ่ม
      }
    } else {
      toast.error(
        `${lang == "th" ? "ส่งข้อมูลติดต่อไม่สำเร็จ" : "Sent contact failure"}`
      )
      // console.log("errors", errors);
    }
  }

  // useEffect(() => {
  //   if (localStorage.getItem("ncl_lang")) {
  //     setLang(localStorage.getItem("ncl_lang"));
  //     i18n.changeLanguage(lang);
  //   } else {
  //     setLang("th");
  //   }
  // }, [lang]);
  return (
    <footer id="contact" className="bg-blue pb-4">
      <div className="container section">
        {/* <SweetAlert2 {...swalProps} /> */}
        <div className="row">
          <div className="col-lg-7">
            <h2 className=" text-white">{t("str_contract_title")}</h2>
            <h5 className=" text-white">{t("str_contract_text1")}</h5>
            <p className=" text-white">{t("str_contract_text2")}</p>
            <div className="d-flex gap-2 align-items-center text-white my-4">
              <i className="fa-solid fa-phone fa-2xl"></i>
              <p className=" text-white m-0 text-nowrap">02-459-4945</p>
            </div>
          </div>
          <div className="col-lg-5">
            <form onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <input
                    value={formData.Name}
                    name="Name"
                    onChange={handleChange}
                    type="text"
                    placeholder={t("str_contract_field_1")}
                    className={`form-control ${errors.Name && "is-invalid"}`}
                  />
                  {/* {errors.Name && <div className="invalid-feedback">{errors.Name}</div>} */}
                </div>
                <div className="col-lg-6 col-12">
                  <input
                    value={formData.lastName}
                    name="lastName"
                    onChange={handleChange}
                    type="text"
                    placeholder={t("str_contract_field_5")}
                    className={`form-control`}
                  />
                </div>
                <div className="col-12">
                  <input
                    value={formData.Phone}
                    name="Phone"
                    onChange={handleChange}
                    type="text"
                    placeholder={t("str_contract_field_2")}
                    className={`form-control ${errors.Phone && "is-invalid"}`}
                  />
                </div>
                <div className="col-12">
                  <input
                    value={formData.Email}
                    name="Email"
                    onChange={handleChange}
                    type="Email"
                    placeholder={t("str_contract_field_3")}
                    className={`form-control ${errors.Email && "is-invalid"}`}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    value={formData.Description}
                    name="Description"
                    onChange={handleChange}
                    type="text"
                    placeholder={t("str_contract_field_4")}
                    className={`form-control ${
                      errors.Description && "is-invalid"
                    }`}
                  ></textarea>
                </div>
                <div className="col-12">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                      <button
                        type="submit"
                        className="btn btn-light w-100"
                        style={{
                          padding: "8px",
                          fontSize: "20px",
                          fontWeight: "normal",
                        }}
                        disabled={isSubmitting} // เพิ่มการตรวจสอบว่าเป็นการส่งข้อมูลหรือไม่
                      >
                        {isSubmitting
                          ? `${
                              lang == "th" ? "กำลังส่งข้อมูล..." : "Sending..."
                            }`
                          : t("str_contract_field_btn")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="text-center bg-dark text-white py-3 ">
        &#169; 2024 NCL INTERNATIONAL LOGISTICS PUBLIC COMPANY LIMITED
      </div>
    </footer>
  )
}

export default Footer;
