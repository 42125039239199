import { useState, useEffect } from "react";
import StrAPI from "../services/StrAPI";

export default function useStrAPI() {
    const [loading, setLoading] = useState(false);
    const [GetDatas, setGetDatas] = useState(null);
    const [getContact, setContactForm] = useState(null);
    const [Path, setPath] = useState(null);

    return {

        loading,
        GetDatas,
        Path,
        getContact,
        setPages: (payload) => {
            setGetDatas(payload);
        },

        setLoading: (payload) => {
            setLoading(payload);
        },

        getStrAPI: async (Path, lang, populate = '&populate=*') => {
            console.log(lang,populate);
            setLoading(true);
            let responseData = await StrAPI.getStrAPI(Path, lang, populate);

            if (responseData) {
                setGetDatas(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    1000
                );
            }
        },

        postForm: async (Path, json) => {
            setLoading(true);
            const responseData = await StrAPI.postAPI(Path, json);

            
            if (responseData) {
                const responseDataEmail = await StrAPI.emailAPI(json);
                console.log(responseDataEmail);
                 
                // setContactForm(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}