// NameNuT 20240423
import axios from "axios";
let baseDomain;
let emailAPIDomain;
baseDomain = "https://admin.nclthailand.com";
emailAPIDomain = "https://api.nclthailand.com";
// baseDomain = 'http://192.168.1.57:1337';

export const customHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Origin": "https://nclthailand.com",  // เพิ่ม Origin header
    "X-Requested-With": "XMLHttpRequest", // หรือเพิ่ม X-Requested-With header
};
export const baseUrl = `${baseDomain}`;
export const baseUrlemail = `${emailAPIDomain}`;

export default axios.create({
  baseUrlemail,
  headers: customHeaders,
});

export const serializeQuery = (query) => {
  return Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join("&");
};
