import React from "react";

function ManagementList({ name, position, src }) {
  return (
    <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mt-5 d-flex flex-column align-items-center">
      <img
        src={src}
        className="logoImg6"
        style={{ width: "300px", height: "350px" }}
      />
      <div
        className="d-flex flex-column justify-content-start mt-3"
        style={{
          backgroundImage: "url(/images/shareholding/นักลงทุนสัมพันธ์-18.jpg)",
          backgroundSize: "300px 90px",
          width: "300px",
          height: "90px",
        }}
      >
        <h4
          className="text-center fw-bold m-0 mt-2 "
          style={{ lineHeight: "1", fontSize: "1.2rem" }}
        >
          {name}
        </h4>
        <p className="text-center m-0  line_2" style={{ fontSize: "14px" }}>
          {position}
        </p>
      </div>
    </div>
  );
}

export default ManagementList;
